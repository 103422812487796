import { UserRoles } from "@/shared/enums";

export default defineNuxtRouteMiddleware(() => {
  const { data: userData } = useAuth();
  if (
    userData.value?.role !== UserRoles.Moderator &&
    userData.value?.role !== UserRoles.Admin
  ) {
    return abortNavigation();
  }
});
